import React from "react";
import phoneIcon from "../images/phone.svg";
import emailIcon from "../images/email.svg";
import Layout from "../components/Layout";
import WhatsAppButton from "../components/WhatsAppButton";
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import ContatoItem from "../components/ContatoItem";
import InsertMetaTags from "../components/InsertMetaTags";
import { localiseText } from "../translations";

const OrcamentoPage = ({ data: { orcamento, contato, site: { siteMetadata: { locale } }  } }) => {
  return (
    <Layout pageTitle={localiseText(locale, 'quote_menu_title')} locale={locale}>
      <InsertMetaTags title="Priscila Tambalo Design - Quote a project" />{" "}
      {renderRichText(orcamento.fraseInicial)}
      {renderRichText(contato.fraseInicial)}
      <ContatoItem
        label={
          <a href={`tel:${contato.telefoneCelular}`}>
            {contato.telefoneCelular}
          </a>
        }
        icon={phoneIcon}
      />
      <ContatoItem
        label={<a href={`mailto:${contato.email}`}>{contato.email}</a>}
        icon={emailIcon}
      />
      <WhatsAppButton numeroWhatsapp={contato.celularWhatsApp}>
        {localiseText(locale, 'request_a_quote')}
      </WhatsAppButton>
    </Layout>
  );
};

export const query = graphql`
query ($locale: String!) {
    orcamento: contentfulOrcamento(node_locale:{eq: $locale}) {
      fraseInicial {
        raw
      }
    }
    contato: contentfulContato(node_locale:{eq: $locale}) {
      fraseInicial {
        raw
      }
      telefoneCelular
      email
      celularWhatsApp
    }
    site { siteMetadata { locale } }
  }
`;

export default OrcamentoPage;
