import styled from "styled-components";
import Button from "../Button";
import { mediaQueries } from "../globalStyles";

export const StyledWhatsAppButton = styled(Button)`
  @media ${mediaQueries.sm} {
    width: 100%;
    justify-content: center;
  }
`;
