import React from "react";
import whatsappIcon from "../../images/whatsapp.svg";
import { StyledWhatsAppButton } from "./style";

export default function WhatsAppButton({ children, numeroWhatsapp }) {
  return (
    <StyledWhatsAppButton
      icon={whatsappIcon}
      style={{ marginTop: "2rem" }}
      forwardedAs="a"
      target="_blank"
      rel="noreferrer"
      href={`https://api.whatsapp.com/send?phone=${numeroWhatsapp}`}
    >
      {children}
    </StyledWhatsAppButton>
  );
}
