import styled from "styled-components";
import { globalColors } from "../globalStyles";

export const StyledContatoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  font-size: 110%;
`;

export const StyledContatoIcon = styled.span`
  background: ${globalColors.secondary};
  padding: 5px;
  border-radius: 32px;
  margin-right: 1rem;
  text-align: center;
  width: 52px;
  height: 52px;
  padding-top: 12px;
`;
