import React from "react";
import { StyledContatoIcon, StyledContatoItem } from "./style";

export default function ContatoItem({ icon, label }) {
  return (
    <StyledContatoItem>
      <StyledContatoIcon>
        <img alt={label} src={icon} width={24} height={24} />
      </StyledContatoIcon>
      {label}
    </StyledContatoItem>
  );
}
